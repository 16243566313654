import { useState, useEffect } from "react";

const useLiveLocation = (isAuth, setLocationAccess, locationAccess) => {
  const [location, setLocation] = useState(null);

  useEffect(() => {
    if (isAuth !== true) {
      return;
    }

    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };

    const successCallback = (position) => {
      if (locationAccess === false) {
        setLocationAccess(true);
      }
      setLocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    };

    const errorCallback = (error) => {
      console.error("Error retrieving geolocation:", error);

      if (error.code === 1) {
        setLocationAccess(false);
      }
    };

    if (locationAccess) {
      const watchId = navigator.geolocation.getCurrentPosition(
        successCallback,
        errorCallback,
        options
      );

      return () => {
        navigator.geolocation.clearWatch(watchId);
      };
    }

    // eslint-disable-next-line
  }, [isAuth, locationAccess]);

  return location;
};

export default useLiveLocation;
