import React, { useRef, useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  ContactIcon,
  FacebookIcon,
  HygieneIcon,
  InstagramIcon,
  SecuredIcon,
  SupportIcon,
  YoutubeIcon,
  LineIcon,
} from "../../assets/Assets";
import Bgblue from "../../assets/Bgblue.svg";
import carouselImage1 from "../../assets/carousel/1.png";
import carouselImage2 from "../../assets/carousel/2.png";
import carouselImage3 from "../../assets/carousel/3.png";
import carouselImage4 from "../../assets/carousel/4.png";
import conductorImage from "../../assets/conductor.jpeg";
import drrsbLogo from "../../assets/DRRSB.svg";
import HyGenie from "../../assets/HyGenie.svg";
import janitorImage from "../../assets/janitor.jpeg";
import publicGirlImage from "../../assets/publicGirl.jpeg";
import waterDroplet from "../../assets/waterDroplet.svg";
import FindPct from "../../components/FindPct/FindPct";
import Header from "../../components/Header";
import Testimonials from "../../components/Testimonials/Testimonials";
import homeBg from "../../assets/homeBg.svg";
import { contactNumber, emergencyContact } from "../../utils/constants";
import { getLocations } from "../../services/database";

export default function LandingPage() {

  const [locations, setLocations] = useState([])

  const sectionRefs = {
    home: useRef(null),
    features: useRef(null),
    about: useRef(null),
    findPct: useRef(null),
    contact: useRef(null),
  };

  async function getPcts(){
    const data = await getLocations()
    setLocations(data)
  }

  useEffect(() => {
    getPcts()
  }, [])
  

  return (
    <>
      <Header sectionRefs={sectionRefs} />
      <div className="w-full flex flex-col justify-center items-center  md:px-0">
        <Home sectionRefs={sectionRefs} />
        <Features sectionRefs={sectionRefs} />
        <AboutSection sectionRefs={sectionRefs} />
        <FindPct sectionRefs={sectionRefs} locations={locations} />
        <ContactSection sectionRefs={sectionRefs} />
      </div>
      <a
        href={`tel:${emergencyContact}`}
        className="md:hidden bg-blue-500 h-12 w-12 rounded-full flex items-center justify-center fixed bottom-4 right-4 shadow-lg"
      >
        <ContactIcon />
      </a>
    </>
  );
}

// Home Section
export const Home = (props) => {
  return (
    <div
      id="home"
      ref={props.sectionRefs.home}
      className="w-full md:flex md:items-center grid  justify-items-center pt-[100px] px-8"
      style={{
        height: `${window.innerHeight - 50}px`,
        backgroundImage: `url(${homeBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: `${window.innerWidth < 700 ? "300px" : "500px"}`,
      }}
    >
      <div className="w-full md:w-[50%] grid  justify-items-center">
        <div className="w-fit">
          <div className="md:text-[50px] text-[25px] text-gray-900 leading-snug">
            Free, Clean, and Safe <br /> Toilets Across{" "}
            <span className="text-black font-extrabold">Chennai</span>
          </div>
          <p className="mt-4 text-gray-600 text-lg">
            Empowering Communities with Accessible <br /> Hygiene Solutions
          </p>
          <button
            className="bg-blue-500 text-white py-3 px-6 mt-6 hover:bg-blue-600"
            onClick={() => {
              const element = document.getElementById("findPct");
              if (element) {
                element.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }
            }}
          >
            Find a nearby toilet
          </button>
        </div>
      </div>

      <div className="w-fit md:w-50% pt-[15px] md:pt-0">
        <img
          src={waterDroplet}
          alt="Water Droplet Character"
          className="md:h-[700px]"
          draggable={false}
        />
      </div>
    </div>
  );
};

// About Section
const AboutSection = (props) => {
  return (
    <div id="about" ref={props.sectionRefs.about} className="w-full">
      <section
        id="story"
        className="py-10 bg-blue-50 w-full px-4 flex flex-col justify-between items-center bg-cover"
        style={{ "--image-url": `url(${Bgblue})` }}
      >
        <div className="max-w-[1330px]">
          <div className="text-xl md:text-3xl font-bold text-gray-800 mb-[6px] pl-2">
            Our Story
          </div>
          <div className="flex flex-col md:flex-row justify-between items-center mt-10 space-y-10 md:space-y-0 md:space-x-10">
            <div className="w-full md:w-1/2 text-left">
              <h3 className="text-xl font-semibold">
                Empowering Public Health and Hygiene with Chennai Toilets
              </h3>
              <p className="mt-4 text-gray-600">
                At Greater Chennai Corporation, we are committed to enhancing
                the quality of life for our citizens. Through the Chennai
                Toilets initiative, we provide free, clean, and accessible
                public restrooms to ensure hygiene and comfort for everyone
                across the city.
              </p>

              <h3 className="text-xl font-semibold mt-8">Our Mission</h3>
              <p className="mt-4 text-gray-600">
                Our mission is simple: to offer a safe, hygienic, and accessible
                sanitation solution in public spaces, making Chennai a cleaner,
                healthier city for all. Whether you're a local resident or a
                visitor, our public toilets are designed to meet your needs—at
                no cost.
              </p>

              <h3 className="text-xl font-semibold mt-8">Our Values</h3>
              <p className="mt-4 text-gray-600">
                To transform public sanitation standards by providing easy
                access to hygienic facilities, promoting cleanliness, and
                supporting public health in Chennai.
              </p>
            </div>

            <div className="w-full md:w-[300px]">
              <img
                src={HyGenie} // Update with correct path
                alt="Hy Genie"
                className="h-96 w-auto mx-auto object-cover"
                draggable={false}
              />
            </div>
          </div>
        </div>
      </section>

      <section id="stories" className="py-20 container mx-auto max-w-[1330px]">
        <StoriesCarousel />
      </section>

      <section id="testimonials" className=" mx-auto">
        <Testimonials />
      </section>
    </div>
  );
};

// Features Section
const Features = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const featuresData = [
    {
      title: "Safe and Secure",
      icon: <SecuredIcon height={50} width={50} color="#242424" />,
      description:
        "Our facilities are designed with your safety in mind, featuring well-lit areas, secure locks, and an emergency alert system for peace of mind.",
    },
    {
      title: "Hygienic & Clean",
      icon: <HygieneIcon height={50} width={50} color="#242424" />,
      description:
        "We ensure top-notch hygiene with regular cleaning schedules and touchless amenities, providing a clean and comfortable experience every time.",
    },
    {
      title: "Help Support",
      icon: <SupportIcon height={50} width={50} color="#242424" />,
      description:
        "Our dedicated support team is always ready to assist, ensuring that your experience is smooth and hassle-free. You can also provide feedback to help us improve.",
    },
  ];

  const slides = [
    <img
      alt="Dinesh"
      src={carouselImage1}
      style={{ maxHeight: `${window.innerHeight - 300}px` }}
    />,
    <img
      alt="Dinesh"
      src={carouselImage2}
      style={{ maxHeight: `${window.innerHeight - 300}px` }}
    />,
    <img
      alt="Dinesh"
      src={carouselImage3}
      style={{ maxHeight: `${window.innerHeight - 300}px` }}
    />,
    <img
      alt="Dinesh"
      src={carouselImage4}
      style={{ maxHeight: `${window.innerHeight - 300}px` }}
    />,
  ];

  const renderCustomIndicators = () => (
    <div className="custom-indicators">
      {slides.map((_, index) => (
        <span
          key={index}
          className={`indicator-dot ${index === currentIndex ? "active" : ""}`}
          onClick={() => setCurrentIndex(index)}
        >
          ●
        </span>
      ))}
    </div>
  );

  return (
    <div
      id="features"
      ref={props.sectionRefs.features}
      className="py-16 max-w-[1330px]"
    >
      <div className="text-xl md:text-3xl font-bold text-gray-800 mb-[6px] pl-6">
        Our Features
      </div>
      <div className="flex flex-wrap gap-5 justify-around pt-6 max-sm:justify-center">
        {featuresData.map((feature, index) => (
          <FeatureCard
            key={index}
            title={feature.title}
            icon={feature.icon}
            description={feature.description}
          />
        ))}
      </div>

      <div className="text-xl md:text-3xl font-bold text-gray-800 mt-[48px] pb-8 pl-6">
        Our Impact
      </div>

      <Carousel
        autoPlay={true}
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={60}
        centerSlidePercentage={50}
        infiniteLoop={true}
        showArrows={false}
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
        onChange={(index) => setCurrentIndex(index)}
        selectedItem={currentIndex}
      >
        {slides.map((data) => (
          <div>{data}</div>
        ))}
      </Carousel>
      {renderCustomIndicators()}
    </div>
  );
};

const FeatureCard = ({ title, description, icon }) => {
  return (
    <div className="bg-blue-100 p-6 shadow-md rounded-lg max-w-[350px] max-sm:w-[100%] h-[340px]">
      <div className="bg-white p-4 rounded-lg h-[100%] overflow-hidden text-center flex flex-col justify-center items-center">
        {icon}
        <h3 className="text-xl font-bold text-blue-500 mt-4">{title}</h3>
        <p className="text-gray-600 m-2">{description}</p>
      </div>
    </div>
  );
};

// Contact Section
const ContactSection = (props) => {
  return (
    <div
      id="contact"
      ref={props.sectionRefs.contact}
      className="px-6 py-14 mt-14 w-full"
    >
      <div className="max-w-[1330px] mx-auto">
        <div className="flex flex-col md:flex-row justify-between md:items-center space-y-8 md:space-y-0">
          <div className="flex flex-col">
            {/* Logo */}
            <div className="mb-6 md:mb-12">
              <img
                src={drrsbLogo}
                alt="drrsbLogo"
                className="h-[50px] md:h-[80px]"
                draggable={false}
              />
            </div>
            <div>
              <h2 className="text-3xl md:text-5xl font-bold leading-snug md:leading-normal">
                We are always happy
                <br />
                to assist you
              </h2>
            </div>
          </div>

          <div className="space-y-8 md:space-y-12 w-full md:w-1/2">
            <div className="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-12 text-lg">
              {/* Email Section */}
              <div>
                <p className="text-gray-800 font-medium mb-2 md:mb-4">
                  Email Address
                </p>
                <LineIcon />
                <p className="text-gray-800 mb-1 mt-2 md:mb-2 text-sm h-5">
                  {/* help@info.com */}
                </p>
                <div className="text-gray-600 text-sm">
                  <p>Assistance hours:</p>
                  <p>Monday - Saturday 9 AM</p>
                  <p>to 6 PM IST</p>
                </div>
              </div>

              {/* Phone Section */}
              <div>
                <p className="text-gray-800 font-medium mb-2 md:mb-4">
                  Phone Number
                </p>
                <LineIcon />
                <p className="text-gray-800 mb-1 mt-2 md:mb-2 text-sm">
                  {contactNumber}
                </p>
                <div className="text-gray-600 text-sm">
                  <p>Assistance hours:</p>
                  <p>Monday - Saturday 9 AM</p>
                  <p>to 6 PM IST</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="mt-12 md:mt-16 pt-8 border-t border-gray-200">
          {/* Social Icons */}
          <div className="flex justify-center space-x-6 mb-6">
            <a
              href="https://www.facebook.com/profile.php?id=61564205083183"
              target="_blank"
              rel="noreferrer noopener"
              className="text-gray-400 hover:text-gray-600 transition-colors"
            >
              <FacebookIcon />
            </a>
            <a
              href="https://www.instagram.com/chennaitoilets?igsh=NWY2aW55dnRzcnF0"
              target="_blank"
              rel="noreferrer noopener"
              className="text-gray-400 hover:text-gray-600 transition-colors"
            >
              <InstagramIcon />
            </a>
            <a
              href="https://www.youtube.com/@CHENNAITOILETS"
              target="_blank"
              rel="noreferrer noopener"
              className="text-gray-400 hover:text-gray-600 transition-colors"
            >
              <YoutubeIcon />
            </a>
          </div>

          {/* Copyright */}
          <div className="text-center text-xs md:text-sm text-gray-500">
            Copy rights reserved @2024
          </div>
        </div>
      </div>
    </div>
  );
};

const ChevronLeft = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    className="w-5 h-5"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M15 19l-7-7 7-7"
    />
  </svg>
);

const ChevronRight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    className="w-5 h-5"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9 5l7 7-7 7"
    />
  </svg>
);

const StoriesCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const stories = [
    {
      id: 1,
      author: "KALAI",
      role: "Janitor",
      content:
        "I am Kalai, I work as a cleaner in toilet number 365. One day, I fell in front of the toilet and hit my head. The supervisor took me to the hospital, and I got stitches on my head. Despite the pain, I went to work the next day because I care about the toilet hygiene. I love my job.",
      images: [publicGirlImage, janitorImage, conductorImage],
    },
    {
      id: 2,
      author: "KUMAR",
      role: "Bus Conductor",
      content:
        "I work as a conductor at Broadway bus stand. I find the urinal and toilet here very useful, and it’s always clean. Workers clean it during working hours, and if anything goes wrong, the maintenance contractor fixes it immediately.",
      images: [janitorImage, conductorImage, publicGirlImage],
    },
    {
      id: 3,
      author: "PUBLIC GIRL",
      role: "Public User",
      content:
        "I use public toilets in the city often, and I must say, they have improved a lot. They're clean, safe, and accessible to everyone. It's great to see such services maintained regularly for public use.",
      images: [janitorImage, publicGirlImage, conductorImage],
    },
  ];

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % stories.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + stories.length) % stories.length
    );
  };

  return (
    <div className="w-full hidden">
      <div className="text-xl md:text-3xl font-bold text-gray-800 mb-8 pl-6">
        Stories
      </div>

      {/* Story content container */}
      <div className="bg-white min-h-[300px] w-[100%] transition-opacity duration-500 ease-in-out flex justify-around items-center">
        {/* Images grid */}
        <div className="w-48 h-48 flex flex-wrap gap-2 transition-all duration-500 ease-in-out transform max-sm:hidden max-md:hidden">
          {stories[currentIndex].images.map((img, index) => (
            <div
              key={index}
              className={`relative transition-transform duration-500 ${
                index === 0
                  ? "transform translate-x-10"
                  : index === 1
                  ? "transform -translate-y-16 z-10"
                  : "transform translate-x-10 -translate-y-28"
              }`}
            >
              <div className="w-32 h-32 overflow-hidden rounded-lg">
                <img
                  src={img}
                  alt={`Story ${index + 1}`}
                  className="w-full h-full object-cover"
                  draggable={false}
                />
              </div>
            </div>
          ))}
        </div>

        {/* Story content */}
        <div className="relative max-w-[600px]">
          <div className="bg-yellow-50 min-h-[250px]  rounded-lg p-6 transition-all duration-300 ease-in-out flex flex-col justify-between ">
            <p className="text-gray-800 mb-4 leading-relaxed">
              {stories[currentIndex].content}
            </p>
            <div className="flex items-center justify-between">
              <div>
                <h3 className="font-bold text-gray-900">
                  {stories[currentIndex].author}
                </h3>
                <p className="text-gray-600">{stories[currentIndex].role}</p>
              </div>
              <button className="bg-blue-500 text-white px-4 py-2 rounded-full text-sm hover:bg-blue-600 transition-colors flex items-center gap-2">
                Read Article
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </button>
            </div>
          </div>
          {/* Navigation buttons */}
          <div className="flex justify-end m-4">
            <div className="">
              <button
                onClick={prevSlide}
                className="p-2 rounded-full bg-gray-100 hover:bg-gray-200 transition-colors mr-2"
                aria-label="Previous story"
              >
                <ChevronLeft />
              </button>
              <button
                onClick={nextSlide}
                className="p-2 rounded-full bg-gray-100 hover:bg-gray-200 transition-colors ml-2"
                aria-label="Next story"
              >
                <ChevronRight />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
