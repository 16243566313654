export const SupportIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={props.height || "24px"}
      width={props.width || "24px"}
      viewBox="0 -960 960 960"
      fill={props.color || "#e8eaed"}>
      <path d="M455.39-140v-60h292.3q5 0 8.66-3.08 3.65-3.07 3.65-8.07v-279.77q0-114.7-82.08-193.12-82.07-78.42-197.92-78.42t-197.92 78.42Q200-605.62 200-490.92v235.53h-30q-28.77 0-49.38-20.03Q100-295.46 100-324.23v-77.69q0-19.46 11.08-35.66 11.08-16.19 28.92-26.03l1.85-51.08q4.92-65.31 33.92-121t74.38-96.96q45.39-41.27 104.77-64.31Q414.31-820 480-820t124.77 23.04q59.08 23.04 104.77 64t74.38 96.65q28.69 55.7 34.23 121l1.85 50.08q17.46 8.23 28.73 23.54Q860-426.38 860-407.54v89.31q0 18.84-11.27 34.15-11.27 15.31-28.73 23.54v49.39q0 29.53-21.19 50.34Q777.61-140 747.69-140h-292.3Zm-87.7-269.23q-14.69 0-25.04-9.96-10.34-9.96-10.34-24.66 0-14.69 10.34-24.84 10.35-10.16 25.04-10.16 14.7 0 25.04 10.16 10.35 10.15 10.35 24.84 0 14.7-10.35 24.66-10.34 9.96-25.04 9.96Zm224.62 0q-14.7 0-25.04-9.96-10.35-9.96-10.35-24.66 0-14.69 10.35-24.84 10.34-10.16 25.04-10.16 14.69 0 25.04 10.16 10.34 10.15 10.34 24.84 0 14.7-10.34 24.66-10.35 9.96-25.04 9.96ZM254.85-472q-6.23-97.92 60.92-167.58 67.15-69.65 166.23-69.65 83.23 0 146.88 51.5 63.66 51.5 77.27 133.34-85.23-1-157.5-44.76-72.27-43.77-110.96-120-15.23 74.61-63.84 131.92-48.62 57.31-119 85.23Z" />
    </svg>
  );
};

export const HygieneIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={props.height || "24px"}
      width={props.width || "24px"}
      viewBox="0 -960 960 960"
      fill={props.color || "#e8eaed"}>
      <path d="M633.91-643.46q-24.83 0-42.45-17.55-17.61-17.56-17.61-42.39 0-24.83 17.55-42.45 17.55-17.61 42.38-17.61 24.84 0 42.45 17.55 17.62 17.56 17.62 42.39 0 24.83-17.56 42.44-17.55 17.62-42.38 17.62ZM820.38-640q-29.15 0-49.57-20.42-20.43-20.43-20.43-49.58t20.43-49.58Q791.23-780 820.38-780q29.16 0 49.58 20.42 20.42 20.43 20.42 49.58t-20.42 49.58Q849.54-640 820.38-640ZM700.05-795.38q-20.82 0-35.43-14.57Q650-824.52 650-845.34q0-20.81 14.57-35.43 14.57-14.61 35.38-14.61 20.82 0 35.43 14.57Q750-866.24 750-845.43q0 20.82-14.57 35.43-14.57 14.62-35.38 14.62ZM250-100q-62.15 0-106.08-41.62Q100-183.23 100-245.39v-206.76q0-28.41 13.28-52.69 13.29-24.28 37.11-39.85l327.3-212.08L490-744.46q17.31 17.31 20.04 41.15 2.73 23.85-9.96 44.39l-56.54 92.77H750q12.75 0 21.37 8.63 8.63 8.62 8.63 21.38t-8.63 21.37q-8.62 8.62-21.37 8.62H336.08l88.15-144.78L183-494.62q-10.92 6.54-16.96 17.85-6.04 11.31-6.04 24.62v206.76q0 37.24 26.44 61.31Q212.88-160 250-160h460q12.75 0 21.37 8.63 8.63 8.63 8.63 21.38 0 12.76-8.63 21.37Q722.75-100 710-100H250Zm250-270.77v-60h330q12.75 0 21.37 8.63 8.63 8.63 8.63 21.38 0 12.76-8.63 21.37-8.62 8.62-21.37 8.62H500Zm0 135.38v-59.99h290q12.75 0 21.37 8.62 8.63 8.63 8.63 21.39 0 12.75-8.63 21.37-8.62 8.61-21.37 8.61H500ZM330-370.77Z" />
    </svg>
  );
};

export const SecuredIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={props.height || "24px"}
      width={props.width || "24px"}
      viewBox="0 -960 960 960"
      fill={props.color || "#e8eaed"}>
      <path d="M438-351.85 650.15-564l-42.77-42.77L438-437.38l-84.77-84.77-42.77 42.77L438-351.85Zm42 251.08q-129.77-35.39-214.88-152.77Q180-370.92 180-516v-230.15l300-112.31 300 112.31V-516q0 145.08-85.12 262.46Q609.77-136.16 480-100.77Zm0-63.23q104-33 172-132t68-220v-189l-240-89.62L240-705v189q0 121 68 220t172 132Zm0-315.62Z" />
    </svg>
  );
};

export const ContactIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={props.height || "24px"}
      width={props.width || "24px"}
      viewBox="0 -960 960 960"
      fill={props.color || "#e8eaed"}>
      <path d="M777.61-140q-113.07 0-227.19-52.58-114.11-52.58-209.69-148.34-95.57-95.77-148.15-209.7Q140-664.54 140-777.61q0-18.17 12-30.28T182-820h130.46q15.15 0 26.73 9.89 11.58 9.88 14.73 24.42L376.85-668q2.38 16.38-1 28.15-3.39 11.77-12.16 19.77l-92.38 89.93q22.3 40.84 50.96 77.27 28.65 36.42 62.04 69.57 32.92 32.93 70 61.16 37.08 28.23 80.08 52.54l89.76-90.54q9.39-9.77 22.74-13.7 13.34-3.92 27.73-1.92l111.07 22.62q15.15 4 24.73 15.46t9.58 26V-182q0 18-12.11 30t-30.28 12ZM242.92-586.92l71.39-68.31q1.92-1.54 2.5-4.23.58-2.69-.19-5l-17.39-89.39q-.77-3.07-2.69-4.61-1.92-1.54-5-1.54H206q-2.31 0-3.85 1.54-1.53 1.54-1.53 3.85 3.07 41 13.42 83.3 10.34 42.31 28.88 84.39Zm348 345.69q39.77 18.54 82.96 28.35 43.2 9.8 80.73 11.65 2.31 0 3.85-1.54t1.54-3.85v-84.15q0-3.08-1.54-5t-4.61-2.69l-84-17.08q-2.31-.77-4.04-.19-1.73.58-3.66 2.5l-71.23 72Zm-348-345.69Zm348 345.69Z" />
    </svg>
  );
};

export const FacebookIcon = (props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      height={props.height || "30px"}
      width={props.width || "30px"}
      fill="currentColor">
      <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
    </svg>
  );
};

export const InstagramIcon = (props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      height={props.height || "30px"}
      width={props.width || "30px"}
      fill="currentColor">
      <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
    </svg>
  );
};

export const YoutubeIcon = (props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      height={props.height || "30px"}
      width={props.width || "30px"}
      fill="currentColor">
      <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z" />
    </svg>
  );
};

export const LocationIcon = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.38313 9.7832L4.31624 11.3623L6.05538 13.913L7.56262 16L9.18581 13.913L11.2728 10.8986L12.1588 9.28764C12.4179 8.8165 12.6374 8.32464 12.815 7.81712L13.0852 7.04521C13.2669 6.52604 13.3597 5.97993 13.3597 5.42988C13.3597 4.60594 13.1515 3.79536 12.7544 3.07342L12.7066 2.98637C12.2978 2.24313 11.7159 1.60941 11.0101 1.13889L10.8644 1.04174C10.5192 0.811634 10.1511 0.617992 9.76592 0.463928L9.55196 0.378342C8.92712 0.128408 8.26034 0 7.58737 0C6.88234 0 6.18441 0.140941 5.53462 0.414536L5.42555 0.460457C4.69438 0.76832 4.03706 1.2282 3.49723 1.80956C3.04209 2.2997 2.67856 2.86749 2.42389 3.48598L2.37409 3.6069C2.12541 4.21086 1.99829 4.85794 2.00002 5.51109C2.00136 6.01768 2.0802 6.5211 2.2338 7.00385L2.46681 7.73616C2.69421 8.45083 3.00159 9.13753 3.38313 9.7832ZM7.56262 8.34782C9.09941 8.34782 10.3452 7.10201 10.3452 5.56522C10.3452 4.02842 9.09941 2.78261 7.56262 2.78261C6.02582 2.78261 4.78001 4.02842 4.78001 5.56522C4.78001 7.10201 6.02582 8.34782 7.56262 8.34782Z"
        fill={props.color || "#E2E2E2"}
      />
    </svg>
  );
};

export const LineIcon = () => {
  return (
    <svg
      width="16"
      height="2"
      viewBox="0 0 16 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="2" fill="black" />
    </svg>
  );
};

export const LeftQuote = () => {
  return (
    <svg
      width="60"
      height="53"
      viewBox="0 0 139 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M138.327 96H84.163V52.7495L110.235 0H134.083L114.681 48.5053H138.327V96ZM54.4132 96H0.046875V52.7495L26.3206 0H50.169L30.7669 48.5053H54.4132V96Z"
        fill="#0075FF"
      />
    </svg>
  );
};
