export const logrocketInit = {
  key:
    process.env.REACT_APP_STAGING === "development"
      ? "byepo/gzero-website-dev"
      : process.env.REACT_APP_STAGING === "UAT"
      ? "byepo/gzero-website-uat"
      : process.env.REACT_APP_STAGING === "production"
      ? "byepo/gzero-website-prod"
      : "",
};

export function calculateDistance(location1, location2) {
    const deg2rad = (deg) => deg * (Math.PI / 180);
  
    if (location1 && location2) {
      const R = 6371; // Radius of the earth in kilometers
      const dLat = deg2rad(location2.latitude - location1.latitude);
      const dLon = deg2rad(location2.longitude - location1.longitude);
  
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(location1.latitude)) *
          Math.cos(deg2rad(location2.latitude)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = Math.round(R * c * 1000); // Distance in meters
  
      return distance;
    } else {
      return null;
    }
  }

  export const emergencyContact = "+04440116022"

  export const contactNumber = "+044 4011 6022"

  export const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };