import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LeftQuote } from "../../assets/Assets";

const testimonials = [
  {
    id: 1,
    // name: "KUMAR",
    text: "I work as a conductor in Broadway bus stand and I find the urinal and toilet here very useful, not only that, it is also very clean, there are workers to clean it during working hours and if there is anything wrong, the maintenance contractor fix it immediately.",
  },
  {
    id: 2,
    // name: "KUMAR",
    text: "I own a juice shop in marina beach, I use the toilet only once in the morning at home and the rest of the day will use the public toilet several times, all the toilets here are free, clean and hygienic, set up for use by all parties, all the shopkeepers here use the public toilet,it's very usefull for us.",
  },
  {
    id: 3,
    // name: "KUMAR",
    text: "I am studying in 2nd year in a women's college here, as we don't have enough facilities in our college toilet, so we use a public toilet near college. Here we have enough comfort, cleanliness and hygiene, there are cleaners here for our safety all the time, so we feel safe here.",
  },
  {
    id: 4,
    // name: "KUMAR",
    text: "I’m Ravi, and I own a shop near the bus stand. I use Chennai Toilets regularly. They’re always clean and well-maintained, making it convenient for everyone in the area. It’s great to have a facility that prioritizes hygiene.",
  },
  {
    id: 5,
    // name: "KUMAR",
    text: "My name is Sangavi. I used the public toilet six months ago, and it was in very poor condition at that time. I didn’t know who was maintaining it, but it was really bad. Now, when I come back, it feels great—it's very clean! They are regularly washing it, and the maintenance is excellent. Thanks to Chennai Toilets!",
  },
  {
    id: 6,
    // name: "KUMAR",
    text: "We are all students from New college Arts and Science, and this is our first time using this toilet. It’s very clean, and they maintain good hygiene. They clean it regularly. Compared to other toilets, there’s no privacy at all—none of the doors can be locked. Chennai Toilets are definitely better. If they expand this facility a bit more, it would be even better.",
  },
];

function Testimonials() {
  const [centerIndex, setCenterIndex] = useState(0);

  const settings = {
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 1000,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    afterChange: (current) => setCenterIndex(current),
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="flex flex-col items-center justify-center bg-blue-100 py-10">
      <div className=" flex flex-col justify-center items-center py-6">
        <div className=" text-3xl font-bold text-gray-800 mb-[6px]">
          Testimonials
        </div>
        <div className=" pt-4 md:text-[20px]">
          What public peoples are saying
        </div>
      </div>
      <div className="w-full max-w-[1330px] px-4">
        <Slider {...settings} className="testimonial-slider">
          {testimonials.map(({ id, text, name }, index) => (
            <div key={id}>
              <div className="p-2 h-[340px] flex flex-row items-end ">
                <div
                  className={` bg-white p-6 rounded-3xl text-center shadow-md flex flex-col items-center overflow-scroll transition-all ${
                    index === centerIndex ? "h-[300px]" : "h-[200px]"
                  }`}>
                  <div className=" pb-2">
                    <LeftQuote />
                  </div>

                  <p className="text-gray-700 text-[18px]">{text}</p>

                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Testimonials;
