import React, { useEffect } from "react";
import LogRocket from "logrocket";
import './App.css';
import LandingPage from './screens/LandingPage/LandingPage';
import { logrocketInit } from "./utils/constants";

function App() {

    //for log rocket init
    useEffect(() => {
      if (
        process.env.NODE_ENV === "production" &&
        window.location.hostname !== "localhost"
      ) {
        if (logrocketInit.key) {
          LogRocket.init(logrocketInit.key);
        }
      }
    }, []);

  return (
    <LandingPage />
  );
}

export default App;
