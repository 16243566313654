import { useEffect, useRef, useState } from "react";
import { LocationIcon } from "../../assets/Assets";
import fallbackLocationImage from "../../assets/fallbackLocationImage.webp";
import useLiveLocation from "../../services/getLocation";
import useMap from "../../services/useMap";
import { calculateDistance, openInNewTab } from "../../utils/constants";


function FindPct(props) {
  const mapContainer = useRef(null);
  const [locationAccess, setLocationAccess] = useState(false);
  const [nearByPCTs, setNearByPCTs] = useState([]);
  const [locationPermissionState, setLocationPermissionState] =
    useState("prompt");

  const currentLocation = useLiveLocation(
    true,
    setLocationAccess,
    locationAccess
  );

  useMap(
    nearByPCTs,
    mapContainer,
    locationAccess,
    currentLocation,
    (objectId) => {
      console.log("objectId", objectId);
    }
  );

  const checkLocationPermissionStatus = () => {
    if (navigator.permissions) {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        setLocationPermissionState(result.state);
      });
    }
  };

  const requestLocationPermission = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        console.log("requestLocationPermission", position);
        setLocationPermissionState("granted");
        window.location.reload();
      },
      (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          console.log("requestLocationERROR", error);
          checkLocationPermissionStatus();
        }
      }
    );
  };

  useEffect(() => {
    if (locationPermissionState === "granted") {
      setLocationAccess(true);
    } else {
      setLocationAccess(false);
    }

    // eslint-disable-next-line
  }, [locationPermissionState]);

  useEffect(() => {
    checkLocationPermissionStatus();
  }, []);

  //   set location data
  useEffect(() => {
    if (currentLocation && props.locations) {
      const locationWithDistance = props.locations.map((location) => {
        const distance = calculateDistance(
          { latitude: location._geoloc.lat, longitude: location._geoloc.lng },
          currentLocation
        );
        return { ...location, distance };
      });

      const sortedData = locationWithDistance.sort(
        (a, b) => a.distance - b.distance
      );
      setNearByPCTs([
        ...sortedData.slice(0, 4),
        {
          type: "userLocation",
          _geoloc: {
            lat: currentLocation.latitude,
            lng: currentLocation.longitude,
          },
        },
      ]);
    }
  }, [props.locations, currentLocation]);

  return (
    <div id="findPct" ref={props.sectionRefs.findPct} className="w-full">
      <div className="flex flex-col justify-center items-center py-8 md:py-14">
        <div className="font-bold text-xl md:text-3xl">Toilets Nearby</div>
      </div>
      <div className="w-full h-fit md:h-[500px] flex justify-center items-center bg-slate-50">
        {locationAccess ? (
          <div className="w-full h-full flex flex-col-reverse md:flex-row">
            <div className="w-full md:w-[40%] h-fit md:h-full pl-4 pt-4  md:pl-10 md:pt-10 flex flex-wrap overflow-scroll">
              {nearByPCTs.length > 0 ? (
                nearByPCTs.map(
                  (data, index) =>
                    data.type !== "userLocation" && (
                      <div
                        key={index}
                        className=" w-[50%] md:w-full pb-4 pl-2 md:pl-4 md:pb-6"
                      >
                        <div className=" flex items-top">
                          <div className=" h-[16px], w-[16px] pt-[6px]">
                            <LocationIcon color="#0075FF" />
                          </div>
                          <div
                            className="font-bold text-lg pl-1 md:pl-2 cursor-pointer"
                            onClick={() => {
                              openInNewTab(
                                `https://www.google.com/maps?q=${data._geoloc.lat},${data._geoloc.lng}`
                              );
                            }}>
                            {data.location}{" "}
                          </div>
                        </div>
                        <div className="text-sm pl-5 md:pl-6 ">
                          {`${(data.distance / 1000).toFixed(2)} Km`}
                        </div>
                        <div className="text-sm pl-5 md:pl-6 ">
                          No: {data.PCTNo}
                        </div>
                        <div className="text-sm pl-5 md:pl-6  ">
                          Ward: {data.ward}
                        </div>
                        <div className="text-sm pl-5 md:pl-6 ">
                          Zone: {data.zone}
                        </div>
                      </div>
                    )
                )
              ) : (
                <p className="text-gray-600">No nearby Toilets found.</p>
              )}
            </div>
            <div
              ref={mapContainer}
              className="w-full md:w-[60%] h-[500px] md:h-full"
            />
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center h-full py-8">
            <img src={fallbackLocationImage} alt="fallbackImg" width="104px" />
            <p className="text-gray-600 mb-2 mt-4 text-center">
              Location access is required to show nearby toilets.
            </p>

            {locationPermissionState === "prompt" && (
              <button
                onClick={requestLocationPermission}
                className="bg-blue-500 text-white px-4 py-2 hover:bg-blue-600 transition-colors"
              >
                Enable Location Access
              </button>
            )}

            {locationPermissionState === "denied" && (
              <p className="text-red-500 px-4 py-2 text-center">
                Location access blocked. Enable in browser settings.
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default FindPct;
