import React, { useState, useEffect } from "react";
import chennaiCorporationLogo from "../assets/chennaiCorporationLogo.png";
import { ContactIcon } from "../assets/Assets";
import { emergencyContact } from "../utils/constants";

const Header = ({ sectionRefs }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeSection, setActiveSection] = useState("home");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.pageYOffset;
    setIsScrolled(scrollTop > 50);

    const sections = ["home", "features", "about", "contact"];
    const isAtBottom =
      window.innerHeight + window.pageYOffset >=
      document.documentElement.scrollHeight - 100;

    if (isAtBottom) {
      setActiveSection("contact");
      return;
    }

    // Find the current section in view
    for (let section of sections) {
      if (sectionRefs[section]?.current) {
        const element = sectionRefs[section].current;
        const rect = element.getBoundingClientRect();
        const elementTop = rect.top;
        const elementHeight = rect.height;

        if (elementTop <= 100 && elementTop > -elementHeight + 100) {
          setActiveSection(section);
          break;
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line
  }, []);

  const scrollToSection = (sectionId) => {
    setIsMenuOpen(false);
    const element = document.getElementById(sectionId);
    if (element) {
      const headerOffset = 80;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
      setActiveSection(sectionId);
    }
  };

  useEffect(() => {
    console.log("Active Section:", activeSection);
  }, [activeSection]);

  return (
    <header
      className={` top-0 w-full z-50 flex items-center justify-between px-8 py-4 transition-all duration-300 ${
        isScrolled
          ? "bg-white/70 shadow-sm backdrop-blur-lg sticky"
          : "absolute"
      }`}>
      <div className="flex items-center space-x-4">
        <img src={chennaiCorporationLogo} alt="Logo" className="h-16 w-auto" draggable={false} />
      </div>

      <div className="hidden md:flex">
        <nav className="flex items-center space-x-8">
          {["home", "features", "about", "contact"].map((section) => (
            <button
              key={section}
              onClick={() => scrollToSection(section)}
              className={`${
                activeSection === section
                  ? "text-blue-500"
                  : "text-gray-700 hover:text-blue-500"
              } transition-colors duration-200 px-2 py-1 rounded`}>
              {section.charAt(0).toUpperCase() + section.slice(1)}
            </button>
          ))}
        </nav>

        <div>
          <button className="bg-blue-500 hover:bg-blue-600 ml-6 text-white font-medium py-3 px-4 flex items-center space-x-2">
            <ContactIcon color="white" />
            <a href={`tel:${emergencyContact}`}>Emergency Contact</a>
          </button>
        </div>
      </div>

      <div className="md:hidden flex items-center">
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="text-gray-700 focus:outline-none"
          aria-label="Toggle menu">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>
      </div>

      {/* Dropdown Menu for Mobile */}
      {isMenuOpen && (
        <div className="absolute top-16 right-8 bg-white shadow-lg rounded-md p-4 z-50 md:hidden">
          <nav className="flex flex-col space-y-2">
            {["home", "features", "about", "contact"].map((section) => (
              <button
                key={section}
                onClick={() => scrollToSection(section)}
                className={`${
                  activeSection === section
                    ? "text-blue-500 font-semibold"
                    : "text-gray-700 hover:text-blue-500"
                } transition-colors duration-200 text-left px-4 py-2 rounded w-full`}>
                {section.charAt(0).toUpperCase() + section.slice(1)}
              </button>
            ))}
          </nav>
        </div>
      )}
    </header>
  );
};

export default Header;
